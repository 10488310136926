import { datadogRum } from '@datadog/browser-rum';

document.addEventListener('DOMContentLoaded', () => {
  let datadogOptions = JSON.parse(document.querySelector('meta[name=datadog]').content)

  datadogRum.init({
    applicationId: datadogOptions.application_id,
    clientToken: datadogOptions.client_id,
    site: 'datadoghq.com',
    service: datadogOptions.service_name,
    env:'production',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask'
  });

  datadogRum.addRumGlobalContext('customer_id', datadogOptions.customer_id);
  datadogRum.addRumGlobalContext('company_id', datadogOptions.company_id);

  datadogRum.startSessionReplayRecording();
});
